/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation, useHistory, Link, useParams } from 'react-router-dom'
import moment from "moment";
import DeleteConfirmDialog from "../../../_metronic/helpers/components/deleteConfirm";
import { directUSAPICall, getMonthDiff, SVformatter, SVPercentageformatter } from "../../../_metronic/helpers/components/utils";
import FileUpload from "./components/FileUpload"
import PageLoader from "../../../_metronic/helpers/components/PageLoader";
import { useSelector } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import _, { truncate } from "lodash";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useStyles } from "../../../_metronic/helpers/components/aggrid/components/utils";
import Button from '@material-ui/core/Button';
import { generateExcel, getAgValuesFormat } from '../../../_metronic/helpers/components/utils'
import { FILTER_AG_STATUS } from '../../../_metronic/helpers/components/constant'
import { getAgreementStatus, AgDifferenceValues } from '../../../_metronic/helpers/components/calculation'
import Badge from '@mui/material/Badge';
import AgreementViewDialog from './components/ViewDataDialog';
import ActionCellRenderer from './components/ActionCellRenderer';
import { UpdateFilter, getFilters, CreateFilter } from '../../../app/modules/assets/redux/AssetsCrud'
import NotesDialog from '../../../_metronic/helpers/components/aggrid/components/NotesDialog'
import FilterSelection from '../../../_metronic/helpers/components/Filter';
import Drawer from '../components/sidedrawer';

export default function TableComponent(props) {
    const { agreementList_Temp, dashBoardAggList, callAssetsList, setSelectedRows, values_filter, selectedRows, setSelectedAg, currentpage } = props;
    const classes = useStyles();
    const location = useLocation()
    const history = useHistory()
    const [deleteId, setDeleteId] = useState([]);
    const { data } = useSelector(({ auth }) => auth.user);
    const [userInfo, setUserInfo] = useState(data);
    const [temptablecolumns, setTempTableColumns] = useState([])

    const [loading, setLoading] = useState(false);
    const [agreementList, setAgreementList] = useState([]);
    const [agreementIdsList, setAgreementIdsList] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const overlayLoadingTemplate =
        '<span className="ag-overlay-loading-center">Loading, Please Wait...</span>';
    const noRowsTemplate =
        '<span className="ag-overlay-loading-center">No records found...</span>';
    const [pageSize, setPageSize] = useState(500);
    const [gridApi, setGridApi] = useState();
    // const [rowDataAPI, setRowDataAPI] = useState([]);
    // const [rowData, setRowData] = useState([]);
    // const externalFilterRef = useRef(null);
    const [tablecolumns, setTableColumns] = useState([])
    const [partnersValues, setPartnersValues] = useState([]);
    const [clientsValues, setClientsValues] = useState([]);
    const [selectedAGId, setSelectedAGId] = useState(false)
    const [showAgrementDetail, setShowAgrementDetail] = useState()
    const [loggedUserRole, setLoggedUserRole] = useState();
    const [userPermission, setUserPermission] = useState({
        canEdit: true,
        canDelete: true,
        canView: true,
        canAdd: true,
        canImport: true,
        canBulkDelete: true,
        canBulkUpdate: true,
        canNotes: true
    });
    const [isSelect, setIsSelect] = useState(true)

    const [selectedColumns, setSelectedColumns] = useState([]);

    const [presetId, setPresetId] = useState()
    const [showFields, setShowFields] = useState(false)
    const [selectedItem, setselectedItem] = useState();
    const [showNotesDialog, setShowNotesDialog] = useState(false);
    const [values, setValues] = useState({
        partner: null,
        client: null,
        aggrement: null
    })
    const [selected, setSelected] = useState([]);
    const [agreementListTemp, setAgreementListTemp] = useState([])
    const [userTypes, setUserTypes] = useState({
        isClient: false,
        isAdmin: false,
        isPartner: false
    });
    const [totalRowDatas, setTotalRowDatas] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(250);
    const [page, setPage] = useState('')
    const [tempColumnDefs, setTempColumnDefs] = useState([]);
    const [tempColumns, setTempColumns] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [colValues, setColValues] = useState([]);
    const [tempFieldsList, setTempFieldsList] = useState([]);
    const gridRef = useRef();
    const [isOwnOverview, setisOwnOverview] = useState(null)
    const urlParams = useParams();

    useEffect(() => {
        if (location && userTypes) {
            let currentpage = location.pathname.split("/")
            if (currentpage[1] === 'leased-aggrement') {
                setisOwnOverview('true')
            } else {
                setisOwnOverview('false')
            }
            setPage(currentpage[1])
        }
    }, [location && userTypes]);

    useEffect(() => {
        if (userInfo) {
            if (userInfo && userInfo.role?.name === 'admin_user' || userInfo.role?.name === 'master_admin') {
                setShowFields(true)
                setUserTypes({
                    isClient: false,
                    isAdmin: true,
                    isPartner: false
                })
            }
        }
    }, [userInfo])

    function onClick(type) {
        setIsSelect(type)
        let temp = tempColumns
        temp.forEach((item) => {
            item.checked = type
        })
        setTempColumns(temp)
    }

    const submitFilter = (isStdView = false) => {
        let selectedColumns = []
        setLoading(true);
        if (presetId) {
            if (isStdView) {
                if (userInfo?.userType === 'CLIENT') {
                    selectedColumns = tempColumns.filter((row) => row.clientview && row.stdview && row.field !== 'actions');
                } else if (userInfo?.userType === 'PARTNER') {
                    selectedColumns = tempColumns.filter((row) => row.partnerview && row.stdview && row.field !== 'actions');
                } else {
                    selectedColumns = tempColumns.filter((row) => row.stdview && row.field !== 'actions' && !row.hide);
                }
            } else {
                if (userInfo?.userType === 'CLIENT') {
                    selectedColumns = tempColumns.filter((row) => row.checked && row.clientview && row.field !== 'actions');
                } else if (userInfo?.userType === 'PARTNER') {
                    selectedColumns = tempColumns.filter((row) => row.checked && row.partnerview && row.field !== 'actions');
                } else {
                    selectedColumns = tempColumns.filter((row) => row.checked && row.field !== 'actions' && !row.hide);

                }
            }
            selectedColumns = selectedColumns.map(
                (status) => status.field
            );
            selectedColumns = ['actions', ...selectedColumns]
            let obj = {
                "status": 'published',
                // "role": currentUser.role.id,
                "filter": null,
                "user_id": userInfo.id,
                "user": userInfo.id,
                "collection": "aggrement",
                "layout_query": {
                    "tabular": {
                        "page": 1,
                        "fields": selectedColumns,
                        "sort": [
                            "-created_at"
                        ],
                        "limit": 500
                    }
                },
            }

            if (selectedColumns?.length > 0) {
                let tempFields = selectedColumns;
                let val = tempColumnDefs.filter((row) => tempFields.indexOf('' + row.field) !== -1)
                tempColumnDefs.forEach((item) => {
                    if (tempFields.some((x) => x == item.field)) {
                        item.checked = true;
                    } else {
                        item.checked = item.field === 'actions' ? true : false;
                    }
                })
                setTempColumns(tempColumnDefs)
                setColumnDefs(val);

            }

            UpdateFilter(obj, presetId)
                .then((response) => {
                    setLoading(false)
                    if (response.data) {
                        gridApi.hideOverlay();

                        setPresetId(response.data.data.id);
                        if (response.data.data.layout_query?.tabular?.fields?.length > 0) {
                            let tempFields1 = response.data.data.layout_query.tabular.fields;
                            listsAllAgreement([], tempFields1)
                        }
                    } else {
                        listsAllAgreement([], tempFieldsList)
                    }

                })
                .catch((err) => {
                    setLoading(false)
                    throw err;
                });
        } else {
            selectedColumns = tempColumns.filter((row) => row.stdview);
            selectedColumns = selectedColumns.map(
                (status) => status.field
            );
            let obj1 = {
                "layout": "tabular",
                "user_id": userInfo.id,
                "user": userInfo.id,
                "status": 'published',
                "bookmark": null,
                "search": null,
                "collection": "aggrement",
                "layout_options": null,
                "layout_query": {
                    "tabular": {
                        "page": 1,
                        "fields": selectedColumns,
                        "sort": [
                            "-created_at"
                        ],
                        "limit": 500
                    }
                },
                "filterfields": null,
                "filter": null,
                "refresh_interval": null
            }

            CreateFilter(obj1)
                .then((response) => {
                    if (response.data) {
                        setPresetId(response.data.id);
                        let tempFields1 = response.data.data.layout_query.tabular.fields;
                        listsAllAgreement([], tempFields1)

                    } else {
                        listsAllAgreement([], tempFieldsList)
                    }
                    // gridApi.hideOverlay();
                })
                .catch((err) => {
                    listsAllAgreement([], tempFieldsList)
                    throw err;
                });
        }

    }



    const getColumnDefinitions = async (gridApi, page) => {
        let options = {
            limit: -1,
            sort: 'order_by_id',
            filter: {
                _and: []
            }
        }
        if (userInfo?.userType === "CLIENT") {
            options.filter = {
                _and:
                    [
                        {
                            "clientview": {
                                _eq: true
                            }
                        }
                    ]
            }
        }
        if (userInfo?.userType === "PARTNER") {
            options.filter = {
                _and:
                    [
                        {
                            "partnerview": {
                                _eq: true
                            }
                        }
                    ]
            }
        }
        options.filter['_and'].push({
            "hide": {
                _eq: false
            }
        })
        const results = await directUSAPICall().items('Agreement_Column_definition').readMany(options).then((result) => {
            if (result) {
                setTempColumnDefs(result.data)
            }
        }).catch((err) => {
        })
    }

    const getPresets = async (gridApi, page) => {
        // if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
        //     callLists()
        //     return
        // }
        let param = `?filter={"_and":[{"collection":{"_eq":"aggrement"}},{"user":{"id":{"_eq":"${userInfo.id}"}}}]}`
        //userInfo?.userType === 'CLIENT'
        getFilters(param)
            .then(async ({ data: { data } }) => {

                if (data && data.length > 0) {
                    setPresetId(data[0].id)
                    if (data[0].layout_query?.tabular?.fields?.length > 0) {

                        let tempFields = data[0].layout_query.tabular.fields;
                        let val = tempColumnDefs.filter((row) => tempFields.indexOf('' + row.field) !== -1)
                        tempColumnDefs.forEach((item) => {
                            if (tempFields.some((x) => x == item.field)) {
                                item.checked = true;
                            } else {
                                item.checked = item.field === 'actions' ? true : false;
                            }
                        })
                        setTempColumns(tempColumnDefs)
                        setColumnDefs(val);
                        let cols = []
                        val.map((item) => {
                            let obj = {};
                            if (item.field !== 'actions' && !item.hide) {
                                obj[item.field] = ""
                                obj = {
                                    'key': item.field,
                                    'value': "",
                                    'placeholder': item.header_name,
                                    'operator': item.operator,
                                    'type': item.type
                                }
                                cols.push(obj)
                            }
                        })
                        setColValues(cols)
                        setTempFieldsList(tempFields)
                        listsAllAgreement([], tempFields)
                    } else {
                        setLoading(true);
                        callLists();
                    }
                } else {
                    setLoading(true);
                    callLists();
                }
            }).catch((err) => {
                listsAllAgreement([], tempFieldsList)
            })
    }

    const callLists = () => {
        let val = [];
        let tempCol = tempColumnDefs

        if (userInfo?.userType === 'PARTNER') {
            val = tempCol.filter((row) => row.partnerview);
        } else if (userInfo?.userType === 'CLIENT') {
            val = tempCol.filter((row) => row.clientview);
        } else {
            val = tempCol.filter((row) => row.stdview);
        }
        let tempFields = val.map(
            (row) => row.field
        );
        tempCol.forEach((item) => {
            if (tempFields.some((x) => x == item.field)) {
                item.checked = true;
            } else {
                item.checked = item.field === 'actions' ? true : false;
            }
        })
        setTempColumns(tempCol)
        // let results = tempColumnDefs.filter(o1 => tempFields.some(o2 => o1.field === o2));
        // console.log("tempColumnDefs", val)
        setColumnDefs(val)
        let cols = []
        val.map((item) => {
            let obj = {};
            if (item.field !== 'actions' && !item.hide) {
                obj[item.field] = ""
                obj = {
                    'key': item.field,
                    'value': "",
                    'placeholder': item.header_name,
                    'operator': item.operator,
                    'type': item.type
                }
                cols.push(obj)
            }
        })
        setColValues(cols);

        listsAllAgreement([], tempFields)
    }

    useEffect(() => {
        if (data && data?.id) {
            setUserInfo(data)
            if (dashBoardAggList) {
                setAgreementList(dashBoardAggList)
                setPageSize(1000)
            }
            if (userInfo?.userType === 'PARTNER') {
                if (!values_filter?.client) {
                    setAgreementList(agreementList_Temp)
                }
            } else if (userInfo?.userType === 'CLIENT') {
                if (!values_filter?.partner) {
                    setAgreementList(agreementList_Temp)
                }
            } else if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
                if (!values_filter?.partner) {
                    setAgreementList(agreementList_Temp)
                }
            }
        }
    }, [data, dashBoardAggList])

    useEffect(() => {
        if (values.partner && values.client) {
          console.log("calll asseetss", values)
          // await callAssetsList(agreementList)
          // const asyncFn = async () => { callAssetsList(agreementListTemp, null, null, values.client) };
          const asyncFn = async () => { listsAllAgreement([], tempFieldsList) };
    
          asyncFn();
        } else if (!values.partner && !values.client) {
          // console.log(selectedRows, "selected", selected)
          // callAssetsList(agreementList)
          // const asyncFn = async () => { callAssetsList(agreementList) };
          // asyncFn();
        }
      }, [values])
      
    useEffect(() => {
        if (!gridApi) {
            return;
        }
        // let allColumnIds = gridApi && gridApi.columnController.gridColumns.map(
        //     (col) => col.colId
        // );
        // let currentMapping = assetTypeFieldMapping.find(
        //   (mapping) => mapping.Asset_Name === selectedItemType
        // );
        let columnsToShow = [];

        // if (!columnsToShow || columnsToShow.length === 0) {
        //     gridApi.columnController.setColumnsVisible(allColumnIds, true);
        //     return;
        // }

        if (columnsToShow.length > 0) {
            columnsToShow.push('actions');
        }
        // gridApi && gridApi.columnController.setColumnsVisible(allColumnIds, false);
        // gridApi && gridApi.columnController.setColumnsVisible(columnsToShow, true);
    }, [gridApi]);


    useEffect(() => {
        if (loggedUserRole) {
            if (loggedUserRole === 'partner_user' || loggedUserRole === 'partner_admin') {
                setUserTypes({
                    isClient: false,
                    isAdmin: false,
                    isPartner: true
                })
                setUserPermission({
                    canAdd: false,
                    canBulkDelete: false,
                    canImport: false,
                    canBulkUpdate: false,
                    canDelete: false,
                    canEdit: false,
                    canView: true
                })
            }
            if (loggedUserRole === 'client_user' || loggedUserRole === 'client_admin') {
                setUserTypes({
                    isClient: true,
                    isAdmin: false,
                    isPartner: false
                })
                let obj = {
                    canAdd: false,
                    canBulkDelete: false,
                    canImport: false,
                    canBulkUpdate: false,
                    canDelete: false,
                    canEdit: false,
                    canNotes: false,
                    canView: true
                }
                // if (isOwnOverview === 'true') {
                //     obj.canAdd = true
                // }
                setUserPermission(obj)

                // setUserPermission({
                //     canAdd: false,
                //     canBulkDelete: false,
                //     canImport: false,
                //     canBulkUpdate: false
                // })
            }
        }
    }, [loggedUserRole])

    const exportExcelData = async () => {
        generateExcel(gridApi, `Agreements-${moment().format('YYYY-MM-DD')}.xlsx`, 'agreements', tablecolumns, loggedUserRole, selectedColumns);
    }

    const onRowSelected = (params) => {
        if (params.api && params.api.getSelectedRows().length > 0) {
            setSelectedRows(params.api.getSelectedRows())
            setSelectedAg(params.api.getSelectedRows().map((e) => e.id))
        } else {
            setSelectedRows([])
            setSelectedAg([])
            // getValues(values_filter.partner, values_filter.client, agreementList_Temp, totalAssetsList, [], upcomingrents)
        }
    };

    // useEffect(() => {
    //     if (selectedRows) {
    //         if (selectedRows?.length > 0) {
    //             let tempselected = selectedRows.map(
    //                 (item) => item.id
    //             );
    //             setLoading(true)
    //             setTimeout(() => {
    //                 setLoading(false)
    //             }, 100)
    //             // getValues(values_filter.partner, values_filter.client, agreementList_Temp, totalAssetsList, tempselected, upcomingrents)
    //             callAssetsList(agreementList_Temp, tempselected, values_filter)
    //             // groupByAgreementAssets()

    //         } else {
    //             setLoading(true)
    //             setTimeout(() => {
    //                 setLoading(false)
    //             }, 100)
    //             // callAssetsList(agreementList_Temp, [], values_filter)
    //         }
    //     }
    // }, [selectedRows])

    useEffect(() => {
        if (userInfo && userInfo.id && userInfo?.role?.name && location) {
            setLoggedUserRole(userInfo.role.name)
            setUserInfo(userInfo)
            // columns.forEach((item) => {
            //     item.checked = true
            // })
            // setTableColumns(columns)
            // setTempColumnDefs(columns)
            // setColState(columns)
            // setSelectedColumns(columns)
            if (dashBoardAggList) {
                setAgreementList(dashBoardAggList)
            }
            // groupByAgreementAssets()

        }
    }, [userInfo, location])

    useEffect(() => {
        if (gridApi && currentPage && page && tempColumnDefs && tempColumnDefs.length > 0) {
            if ((page !== 'overview')) {
                getPresets()
            }
        }
        //  setData(datas);
    }, [gridApi, tempColumnDefs, currentPage, page]);


    useEffect(() => {
        if (location) {
            let currentpage = location.pathname.split("/")
            setPage(currentpage[1])
        }
    }, [location]);


    // location.pathname !== '/dashboard'
    const listsAllAgreement = async ([], tempFields) => {
        setLoading(true)
        // let fields = '?limit=1&fields=ag_status,heading,agrement_info_no,eol_date,lease_start_date,type_of_product,client.name,client.clients_org_no,partner.partner_org_no,partner.partner_name,sum_purchase_value'
        let fields = ''
        if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
            fields = `${tempFields.toString()},montly_payment_ratio,notes,interim_commision,sum_purchase_value,sum_purchase_value_1,id,eol_date,lease_start_date,supplier_invoice_number,no_of_month_lease,user_created.first_name,user_created.last_name,user_updated.first_name,user_updated.last_name,bank.bank_name,bank.bank_org_no,bank.interim_percentage,supplier.supplier_name,supplier.supplier_org_no,client.id,client.name,client.clients_org_no,partner.id,partner.partner_org_no,partner.partner_name`
        } else {
            fields = `montly_payment_ratio,notes,bank.bank_name,bank.bank_org_no,bank.interim_percentage,id,supplier_invoice_number,id,EOL_action_results,total_assets,enable_EOL_choice,no_of_month_lease,lease_start_date,our_agreement_ref,heading,ag_status,eol_date,no_of_month_lease,sum_purchase_value,montly_payment_rent_or_lease,agrement_info_no,partner,client.id,client.name,client.clients_org_no,partner.id,partner.partner_org_no,partner.partner_name,sum_purchase_value_1`
        }
        let options = {
            limit: -1,
            sort: '-lease_start_date',
            fields: [fields],
            meta: 'filter_count',
            filter: {
                _and: []
            }
        }
        // options.filter = {
        //     "id": {
        //         _eq: 6620
        //     }
        // }
        if (userInfo.role) {
            if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {

                // options.filter = {
                //     "client.id": {
                //         _eq: userInfo.client.id
                //     }
                options.filter = {
                    _and:
                        [
                            {
                                "client.id": {
                                    _eq: userInfo.client.id
                                }
                            }
                        ]
                }
                // }
                if (values.partner && values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: values.partner
                                    }
                                },
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
                options.filter['_and'].push({
                    "ag_status": {
                        _in: FILTER_AG_STATUS
                    }
                })
            } else if (userInfo.role.name === 'partner_admin' || userInfo.role.name === 'partner_user') {
                // console.log("users_details", users_details)
                options.filter = {
                    "partner.id": {
                        _eq: userInfo.partner.id
                    }
                }
                options.filter = {
                    _and:
                        [
                            {
                                "partner.id": {
                                    _eq: userInfo.partner.id
                                }
                            }
                        ]
                }
                if (values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: userInfo.partner.id
                                    }
                                },
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
                options.filter['_and'].push({
                    "ag_status": {
                        _in: FILTER_AG_STATUS
                    }
                })
            } else {
                if (values.client && !values.partner) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
                if (values.partner && !values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: values.partner
                                    }
                                },

                            ]
                    }
                }
                if (values.partner && values.client) {
                    options.filter = {
                        _and:
                            [
                                {
                                    "partner.id": {
                                        _eq: values.partner
                                    }
                                },
                                {
                                    "client.id": {
                                        _eq: values.client
                                    }
                                }
                            ]
                    }
                }
            }
            //chekc client own agreement
            // options.filter['_and'].push({
            //     "isclientownaggreement": {
            //         _eq: isOwnOverview === 'true' ? true : false
            //     }
            // })
            if (urlParams?.year) {
                options.filter['_and'].push({
                    "lease_years": {
                        _contains: urlParams?.year
                    }
                })
            }


            if ((userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") && (localStorage.getItem("globalsearchField") && localStorage.getItem("globalsearchAgreements"))) {
                options.filter['_and'].push({
                    "id": {
                        _in: localStorage.getItem("globalsearchAgreements")
                    }
                })
            }

        }
        const result = await directUSAPICall().items('aggrement').readMany(options);
        if (result.data && result.data.length > 0) {
            setAgreementList(result.data)
            setAgreementListTemp(result.data)
            setTotalRowDatas(result.meta.filter_count)
            callClient() // call client list
            setLoading(false)
        } else {
            callClient() // call client list
            setLoading(false)
            setAgreementList([])
            setAgreementListTemp([])
        }
    }

    const callClient = () => {
        if (data.role) {
            if (data.role.name === 'client_admin' || data.role.name === 'client_user') {
                values.client = data.client.id;
                getClientList(null, data?.client?.id)
            }
            else if (data.role.name === 'partner_admin' || data.role.name === 'partner_user') {
                values.partner = data?.partner?.id
                getClientList(data?.partner?.id, null)
            } else {
                getClientList(null, null)
            }
        }
    }

    const gotoEdit = (values, type) => {
        // console.log("propppss", values)
        if (type === 'view') {
            setSelectedAGId(values.id)
            setShowAgrementDetail(true)
        } else {
            history.push(`/updateagreement/${values.id}`)
        }
    }
    const deleteConfirm = (values) => {
        setShowDeleteDialog(true)
        let arr = [];
        arr.push(values)
        setDeleteId(arr)
    }

    const EOLChoiceRender = (params) => {
        let color = ''
        if (params.data) {
            // let monthDiff = getMonthDiff(params.data.eol_date);
            // console.log("params.data.EOL_action_results", params.data.EOL_action_results)
            let EOL_action_results = params.data.EOL_action_results;
            // let eol_month = params.data.eol_month;
            let total_assets = params.data.total_assets;

            let eolchoice = params.data.enable_EOL_choice;
            // let eolnoreminder = params.data.EOL_no_reminder;
            // if (eol_month <= monthDiff) {
            // console.log(params.data.id, "eolchoice*********", params.data.enable_EOL_choice)
            // console.log(params.data.id, "eolnoreminder*********", total_assets)
            if (total_assets > 0) {
                if (eolchoice === true && !EOL_action_results) {
                    color = 'red'
                } else if (eolchoice === true && (EOL_action_results >= 0 && EOL_action_results < total_assets)) {
                    color = 'yellow'
                } else if (eolchoice === true && EOL_action_results === total_assets) {
                    color = 'green'
                }
            }
        }
        return <>
            {
                color ? <Badge>
                    <i className="fas fa-circle" style={{ color: color }} />
                </Badge> : null
            }
        </>
    }


    const LinkCellRenderer = (params) => {
        let color = ''
        let red = ''
        if (params.data && params.data.ag_status) {
            let monthDiff = getMonthDiff(params.data.eol_date);
            let status = params.data.ag_status.toUpperCase();
            if (status === 'INTERIM') {
                color = '#eff2f5'
            } else if (status === 'ONGOING') {
                color = 'green'

            } else if (status === 'ENDING') {
                color = 'blue'
                if (monthDiff >= 3 && monthDiff <= 6) {
                    color = 'blue'
                } else if (monthDiff <= 3) {
                    color = 'red'
                }
            } else if (status === 'CLOSED') {
                color = 'black'
            } else if (status === 'PREPARE') {
                color = 'black'
            } else if (status === 'EXTENDED') {
                color = 'yellow'
            } else if (status === 'ENDED') {
                color = 'black'
            }
            // Colors
            // White -Interim
            // Green-Ongoing
            // Red-EOL choice 3 months before EOL date
            // Yellow-Extended
            // Black-Ended
            if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
                let diff = AgDifferenceValues(params.node.data)
                if (diff >= 500) {
                    red = 'red';
                }
            }


        }
        return <>
            {
                userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER" ? <Link target="_blank" to={`/agrementassets/${params.data.id}`}>{params.value}</Link> : <><Link target="_blank" to={`/agrementassetsNew/${params.data.id}`}>{params.value}</Link> {red && <Badge size="small">
                    <i className="fas fa-circle" style={{ color: red }} />
                </Badge>}</>
            }
        </>
    }

    const handleDifference = (gridApi) => {
        setLoading(true)
        let tempValues = agreementList;
        let values = []
        tempValues.forEach((item) => {
            let diff = AgDifferenceValues(item)
            if (diff >= 500) {
                values.push(item)
            }
        })
        setAgreementList(values);
        setLoading(false)
    }

    const handleResetFilter = () => {
        setLoading(true)
        let tempValues = agreementListTemp;
        setAgreementList(tempValues);
        setLoading(false)
    }


    const BtnActionCellRenderer = (params) => {

        return <div>
            <i onClick={() => gotoEdit(params, 'view')} className="fas fa-eye" />
            {
                (params?.data?.user_created && userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") ? ((userInfo?.id === params.data.user_created.id) ? <i onClick={() => gotoEdit(params)} className="fas fa-edit" /> : null) : <i onClick={() => gotoEdit(params)} className="fas fa-edit" />
            }
            {
                (params?.data?.user_created && userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") ? (userInfo?.id === params.data.user_created.id) ? params.data.totalAssets === 0 ? <i onClick={() => deleteConfirm(params)} className="fas fa-trash-alt deleteone" /> : null : null : params.data.totalAssets === 0 ? <i onClick={() => deleteConfirm(params)} className="fas fa-trash-alt deleteone" /> : null
            }
        </div>
    }

    const ActionRendererComponent = ({ data }) => {
        let props = data
        return (
            props ?
                <div>
                    {
                        (userPermission && userPermission.canView) && <i onClick={() => gotoEdit(props, 'view')} className="fa fa-eye" />
                    }
                    {(userPermission && userPermission.canEdit) && (
                        <Link className="viewbtn" to={`${"/updateagreement/" + props.id}`} ><i className="fa fa-edit" /></Link>
                    )}
                    {(userPermission && userPermission.canNotes) && <i className={`${props?.notes ? 'fas' : 'far'} fa-sticky-note`} onClick={() => handleNotestOpen(props)}></i>}
                    {(userPermission && userPermission.canDelete) && <i className="fa fa-trash-alt deleteone" onClick={() => deleteConfirm(props)} />}
                </div> : null
        );
    }

    const buildColumnDefinitions = (columnDefs) => {
        return columnDefs && columnDefs.map((columnDef, index) => {
            let columnDefinition = {
                headerName: (page !== 'overview') ? index !== 0 ? columnDef.header_name : '' : columnDef.title,
                cellRenderer: index === 0 && (page !== 'overview') ? ActionRendererComponent : columnDef.field === 'agrement_info_no' ? LinkCellRenderer : (columnDef.field === 'enable_EOL_choice') ? EOLChoiceRender : false,
                headerCheckboxSelection: index === 0 && (page !== 'overview') ? true : false,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: index === 0 && (page !== 'overview') ? true : false,
                field: columnDef.field,
                // editable: columnDef.editable,
                filter: (page !== 'overview') ? (index === 0) ? 'none' : 'agTextColumnFilter' : 'agTextColumnFilter',
                sortable: true,
                resizable: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                enableFilter: true,
                // flex: 1,
                // wrapText: true,
                // autoHeight: true,
                // hide: !showFields && checkFields(columnDef.field),
                hide: columnDef.hide,
                // tooltipField:columnDef.field,
                width: 'auto',
                cellClass: columnDef.type === 'currencyColumn' ? 'ag-right-aligned-cell' : '',
                valueGetter: (params) => {
                    params.data.clients_attn = params.data?.clients_attn?.first_name ? params.data?.clients_attn?.first_name + ' ' + params.data?.clients_attn?.last_name : null
                    if (params.data.client) {
                        params.data.client_name = params.data.client.name ? params.data.client.name : ''
                        params.data.clients_org_no = params.data.client.clients_org_no ? params.data.client.clients_org_no : ''
                    }
                    if (params.data.supplier) {
                        params.data.supplier_name = params.data.supplier.supplier_name
                        params.data.supplier_org_no = params.data.supplier.supplier_org_no
                    }
                    if (params.data.bank) {
                        params.data.bank_name = params.data.bank.bank_name
                        params.data.bank_org_no = params.data.bank.bank_org_no;
                        let income_commision = params.data.Interim_income ? (parseFloat(params.data.Interim_income) / 100) * params.data.bank.interim_percentage : 0;
                        params.data.interim_commision = income_commision ? Math.round(income_commision) : 0;
                    }
                    if (params.data.partner) {
                        params.data.partner_org_no = params.data.partner.partner_org_no
                        params.data.partner_name = params.data.partner.partner_name
                    }
                    if (params.data.user_updated) {
                        params.data.user_updated = params.data?.user_updated?.first_name ? params.data?.user_updated?.first_name : '' + ' ' + params.data?.user_updated?.last_name ? params.data?.user_updated?.last_name : '';
                    }
                    if (params.data.user_created) {
                        let usercreated = params.data?.user_created
                        params.data.user_created = usercreated.first_name
                        // params.data.user_created = (params.data?.user_created?.first_name ? params.data?.user_created?.first_name : '') + ' ' + (params.data?.user_created?.last_name ? params.data?.user_created?.last_name : '');
                    }
                    if (params.data.sum_purchase_value && params.data.montly_payment_ratio) {
                        let rent = Math.round(params.data.montly_payment_rent_or_lease) * 3
                        params.data.quartely_rent = rent
                        // params.data.user_created = (params.data?.user_created?.first_name ? params.data?.user_created?.first_name : '') + ' ' + (params.data?.user_created?.last_name ? params.data?.user_created?.last_name : '');
                    }
                    // console.log("user_created",params.data?.user_created)
                    if (params.data.ag_status) {
                        let today = moment().format('YYYY-MM-DD');
                        let status = params.data.ag_status
                        if (params.data && params.data.ag_status && params.data.lease_start_date) {
                            let monthDiff = getMonthDiff(params.data.eol_date);
                            let ag_status = getAgreementStatus(params.data, monthDiff).status || ''
                            let statusList = ["EXTENDED"]
                            if (!statusList.includes(params.data.ag_status)) {
                                params.data.ag_status = ag_status
                            }
                            // if (ag_status === params.data.ag_status) {
                            //     params.data.ag_status = ag_status;
                            // }
                        } else if (params.data && params.data.ag_status && today > params.data.eol_date && status === 'ONGOING' && status !== 'CLOSED') {
                            //params.data.ag_status = 'EXTENDED'
                            // if (ag_status === params.data.ag_status) {
                            //     params.data.ag_status = ag_status;
                            // }
                        }
                    }
                    if (!params.data.total_assets) {
                        params.data.total_assets = 0
                    }
                    if (columnDef.type === 'currencyColumn') {
                        return params.data[columnDef.field] ? SVformatter(params.data[columnDef.field]) : '0';
                    }
                    return params.data[columnDef.field];
                },
                valueFormatter: (params) => {
                    // console.log("params", params.data.aggrement)

                    if (columnDef.type === 'currencyColumn') {
                        return params.value ? (params.value) + " kr" : '0 kr';
                    }
                    if (params.value && columnDef.type === 'date') {
                        return moment(params.value).format('YYYY-MM-DD');
                    }
                    if (columnDef.type === 'percentage') {
                        return params.value ? SVPercentageformatter(params.value) + " %" : '0%';
                    }

                },
            };
            // if (columnDef.field === 'totalAssets') {
            //     columnDefinition.cellRenderer = params => {
            //         params.data.totalAssets = getAssetsCount(params.data.id)
            //         return params.data.totalAssets
            //     };

            // }
            return columnDefinition;
        });
    };

    const frameworkComponents = {
        BtnActionCellRenderer,
        LinkCellRenderer,
        ActionCellRenderer,
        EOLChoiceRender,
        ActionRendererComponent
    };
    const onModelUpdated = (params) => {
        // console.log("onModelUpdated" ,params)
        // if (gridApi.getSelectedRows().length > 0) {
        //     gridApi && gridApi.deselectAll()
        // }
        // params.api.sizeColumnsToFit();
    };

    const dashboardColumns = [
        {
            title: 'AG. ref',
            field: 'agrement_info_no',
            filterPlaceholder: 'Agrement ref',
        },
        {
            title: 'Client',
            field: 'client_name',
        },
        {
            title: 'Status',
            field: 'ag_status',
        },
        {
            title: 'Equipment',
            field: 'heading',
            filterPlaceholder: 'heading'
        },
        {
            title: 'Lease start',
            filterPlaceholder: 'Lease start',
            field: 'lease_start_date',
            type: 'date'
        }, {
            title: 'Lease end',
            field: 'eol_date',
            filterPlaceholder: 'Lease end',
            type: 'date'
        },
        {
            title: 'No of months',
            filterPlaceholder: 'No of months',
            field: 'no_of_month_lease',

        }, {
            title: 'Asset value',
            filterPlaceholder: 'Asset value',
            field: 'sum_purchase_value',
            type: 'currencyColumn'
        },
        // {
        //     title: 'Partner',
        //     field: 'partner_name',
        //     filterPlaceholder: 'Partner Name',
        //     valueGetter: function (params) {
        //         if (params.data.partner) {
        //             params.data.partner_name = params.data.partner.partner_name
        //             params.data.partner_org_no = params.data.partner.partner_org_no
        //         }
        //         return params.data.partner_name
        //     }
        // }, {
        //     title: 'Client',
        //     field: 'client_name',
        //     filterPlaceholder: 'Client Name',
        //     valueGetter: function (params) {
        //         // console.log("params.data", params.data)
        //         params.data.client_name = ''
        //         if (params.data.client) {
        //             params.data.client_name = params.data.client.name
        //             params.data.clients_org_no = params.data.client.clients_org_no
        //         }
        //         return params.data.client_name
        //     }
        // },
        {
            title: 'Rent per month',
            filterPlaceholder: 'Rent per month',
            field: 'montly_payment_rent_or_lease',
            type: 'currencyColumn',
        },

    ]

    const onGridReady = (params) => {
        if (!currentpage) {
            getColumnDefinitions();
        }
        setGridApi(params.api);
    };

    const handleBulkDelete = async (props) => {
        let selectedRows = gridApi.getSelectedRows();
        setDeleteId(selectedRows)
        setShowDeleteDialog(true)
    };


    const getAgValuess = async (partner, client, agreementList_Temp, totalAssets_List, selectedAgIds) => {
        let values = await getAgValuesFormat(partner, client, agreementList_Temp, totalAssets_List, selectedAgIds, userTypes, 'agpage')
        setAgreementList(values.agreementList)
    }

    const getClientList = async (partnerId = null, client = null) => {
        let options = {
            limit: -1,
            fields: [
                `client.name,client.id,client.clients_org_no,partner.partner_org_no,partner.partner_name,partner.id`,
            ],
            filter: {
                _and: []
            }
        }
        if (partnerId) {
            values.partner = partnerId

            options.filter['_and'].push({
                "partner": {
                    _eq: partnerId
                }
            })
        }
        if (client && userInfo.userType !== 'CLIENT') {
            values.client = client;
            options.filter['_and'].push({
                "client": {
                    _eq: client
                }
            })
        }

        if (userInfo.userType === 'CLIENT') {
            options.filter['_and'].push({
                "client": {
                    _in: userInfo.clientaccess ? userInfo.clientaccess : userInfo.client.id
                }
            })
        }

        const result = await directUSAPICall().items('client_address').readMany(options);
        if (result?.data && result.data.length > 0) {
            if (!partnerId) {
                values.client = "";
                setValues({ ...values })
                let partners = result.data.map(
                    (item) => item.partner && item.partner
                );
                partners = partners.filter(function (e) { return e != null; });
                partners = _.orderBy(partners, ['partner_name'], ['asc']);
                setPartnersValues(_.uniqBy(partners, 'id'))
            }
            if (partnerId || client) {
                // values.partner = "";
                let clients = result.data.map(
                    (item) => item.client
                );
                clients = _.orderBy(clients, ['name'], ['asc']);
                values.client = '';
                setValues({ ...values })
                setClientsValues(_.uniqBy(clients, 'id'))
            }
        }
    }
    const handleNotesCancel = async () => {
        setShowNotesDialog(false);
        listsAllAgreement([], tempFieldsList);
    };

    const handleNotestOpen = (params) => {
        setShowNotesDialog(true);
        setselectedItem(params)
    };

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 225,
            floatingFilter: true,
        };
    }, []);



    return (
        <>
            <PageLoader pageLoaderOpen={loading} setPageLoaderOpen={setLoading} />
            {
                (page !== 'overview') &&
                <div className="header-top">
                    {

                        <div className="card-toolbar btns">
                            {(page !== 'overview') && (userPermission && userPermission && userPermission.canBulkDelete) &&
                                <Link className='align-self-center' to="#" onClick={() => gridApi && gridApi.getSelectedRows().length > 0 && handleBulkDelete(gridApi)}>
                                    <i className="fas fa-trash-alt">
                                    </i>{'Bulk delete'}
                                </Link>
                            }
                            {(page !== 'overview') && (userPermission && userPermission && userPermission.canBulkDelete) &&
                                <Link className='align-self-center' to="#" onClick={() => gridApi && handleDifference(gridApi)}>
                                    <i className="fas fa-not-equal"></i>{'Difference'}
                                </Link>
                            }
                            {(page !== 'overview') && (userPermission && userPermission && userPermission.canBulkDelete) &&
                                <Link className='align-self-center' to="#" onClick={() => gridApi && handleResetFilter(gridApi)}>
                                    <i class="fas fa-redo"></i>{'Reset Filter'}
                                </Link>
                            }
                            {
                                ((userPermission && userPermission && userPermission.canImport) && ((page !== 'overview')) && (
                                    <Link className='align-self-center' to="#" onClick={() => setFileUploadOpen(true)}><i to='#' className='fas fa-upload'>
                                    </i>{'Import file'}</Link>))
                            }
                            {
                                (page !== 'overview') && <Link className='align-self-center' to="#" onClick={() => exportExcelData()}><i className="fas fa-download">
                                </i>{'Export file'}
                                </Link>
                            }
                            {
                                ((page !== 'overview') && userPermission.canAdd) && <Link className='align-self-center' to={`/createagreement`} className='align-self-center'>
                                    <Button variant="contained" color="primary" className={classes.button}>
                                        <i className="fas fa-plus"></i> {'Create Agreement'}
                                    </Button>
                                    {/* <button type="button" className="btn btn-primary">Create Asset</button> */}
                                </Link>
                            }
                        </div>
                    }
                </div>
            }
            {
                (page !== 'overview') &&
                <div>
                    <FilterSelection
                        userTypes={userTypes}
                        userRole={loggedUserRole}
                        agreementList={agreementList}
                        agreementListTemp={agreementListTemp}
                        clientsValues={clientsValues}
                        partnersValues={partnersValues}
                        selected={selected}
                        title="Agreements"
                        setSelected={setSelected}
                        setValues={setValues}
                        values={values}
                        getClientList={getClientList}
                        getValues={getAgValuess}
                    />
                </div>
            }
            <div className={`card card-custom gutter-b bg-smoke${page === 'overview' ? ' no-padd' : ' padd'} stat`}>
                <div className='card-header border-0 card-header-sm'>
                    {
                        (page !== 'overview') &&
                        <Drawer
                            columnDefs={tempColumns}
                            setTempColumnDefs={setTempColumns}
                            submitFilter={submitFilter}
                            onClick={onClick}
                            setIsSelect={setIsSelect}
                            isSelect={isSelect}
                        />
                    }
                    <div className={`card-title ${page}-title`}>
                        <h3 className='card-label text-bold'>
                            {'Agreements'}
                        </h3>
                    </div>
                </div>
                <div
                    className='ag-theme-quartz'
                    style={{
                        width: '100%',
                        height: `${page === 'overview' ? userTypes?.isAdmin ? '674px' : '520px' : '80vh'}`,
                        boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        // getRowClass={userTypes.isAdmin ? getRowClass : false}
                        rowData={(page !== 'overview') ? agreementList : dashBoardAggList}
                        rowBuffer={500}
                        debounceVerticalScrollbar={true}
                        columnDefs={buildColumnDefinitions((page !== 'overview') ? columnDefs : dashboardColumns)}
                        frameworkComponents={frameworkComponents}
                        suppressDragLeaveHidesColumns={true}
                        onGridReady={onGridReady}
                        rowSelection='multiple'
                        enableRangeSelection={true}
                        applyColumnDefOrder={true}
                        sideBar={false}
                        enableCellTextSelection={true}
                        overlayLoadingTemplate={overlayLoadingTemplate}
                        overlayNoRowsTemplate={noRowsTemplate}
                        handleNotestOpen={handleNotestOpen}
                        pagination={true}
                        paginationPageSize={pageSize}
                        rowMultiSelectWithClick={true}
                        // suppressRowClickSelection={true}
                        alwaysShowVerticalScroll={true}
                        // scrollbarWidth={'2000'}
                        gotoEdit={gotoEdit}
                        deleteConfirm={deleteConfirm}
                        floatingFilter={true}
                        onModelUpdated={onModelUpdated}
                        onRowSelected={page === 'overview' ? onRowSelected : false}
                        userPermission={userPermission}
                        maxBlocksInCache={pageSize}
                        getRowHeight={10}
                        cacheOverflowSize={100}
                        maxConcurrentDatasourceRequests={-1}
                        infiniteInitialRowCount={1}
                        filter={true}
                    ></AgGridReact>
                </div>

            </div>
            <DeleteConfirmDialog
                title="Agreemet"
                content="are you sure you want to delete this Agreement"
                open={showDeleteDialog}
                setOpen={setShowDeleteDialog}
                tableName="aggrement"
                deleteId={deleteId}
                tempFieldsList={tempFieldsList}
                getNewData={listsAllAgreement}
                parentGridApi={gridApi}
            />
            <FileUpload
                open={fileUploadOpen}
                setOpen={setFileUploadOpen}
                allAssets={agreementIdsList}
                title='File upload'
                handleCancel={setFileUploadOpen}
                tempFieldsList={tempFieldsList}
                getNewData={listsAllAgreement}
            />
            <AgreementViewDialog
                open={showAgrementDetail}
                setOpen={setShowAgrementDetail}
                fieldDisable={true}
                title={`Agreement - ${selectedAGId}`}
                selectedAGId={selectedAGId}
                setSelectedAGId={setSelectedAGId}
            />
            <NotesDialog
                handleCancel={handleNotesCancel}
                // userRole={userRole}
                selectedItem={selectedItem}
                showDialog={showNotesDialog}
                collection="aggrement"
                userPermission={userPermission}
                getNewData={listsAllAgreement}
            />
            {/* <Sidebar columns={temptablecolumns} onChange={handleChange} selectedColumns={selectedColumns} /> */}

        </>
    )
}

export { TableComponent }

