import { API_URL, FILTER_ASSET_STATUS } from './constant'
import * as XLSX from 'xlsx';
import axios from 'axios'
import moment from "moment";
import { Directus } from '@directus/sdk';
import { getagreementassetsList, updateassetsList } from "../../../app/modules/assets/redux/AssetsCrud";
import _ from "lodash";
import { getAgreementStatus } from "./calculation";
import * as Excel from "exceljs";

export function directUSAPIToken() {
    const directus = new Directus(API_URL);
    const token = directus.auth.token;
    return token
}

export function shadowToken() {
    const shadowToken = localStorage.getItem("shadowToken");
    return shadowToken
}

export function mainToken() {
    const mainToken = localStorage.getItem("mainToken");
    return mainToken
}

export function directUSAPICall() {
    const directus = new Directus(API_URL);

    const token = directus.auth.token;
    const result = new Directus(API_URL, {
        auth: {
            token: token
        },
    });
    return directus
}
// const fetch = require('node-fetch');
export function cleanEmptyObj(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj
}

export function getMonthDiff(eoldate) {
    let today = moment().format('YYYY-MM-DD')
    const getDate = (date) => moment(date, 'YYYY-MM-DD').startOf('month')
    eoldate = moment(eoldate).format('YYYY-MM-DD');
    let diff = 0
    if (eoldate > today) {
        diff = Math.abs(getDate(today).diff(getDate(eoldate), 'months'));
    }
    // console.log("diff", diff)
    return diff
}

export function checkgreaterthanEol(eoldate) {
    let today = moment().format('YYYY-MM-DD')
    eoldate = moment(eoldate).format('YYYY-MM-DD');
    let diff = false
    if (eoldate < today) {
        diff = true
    }
    // console.log("diff", diff)
    return diff
}

export function numberWithCommas(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '';
}

export function SVformatter(v) {
    // return v ? new Number(Math.round(v)).toLocaleString('sv-Se', {currency: 'SEK', style: 'currency'}) : 0;
    return v ? new Number(Math.round(v)).toLocaleString("sv-SE", { minimumFractionDigits: 0 }) : 0;
}
export function SVPercentageformatter(x) {
    return x ? (x).toString().replace(".", ",") : '';
}
export function SVRemovePercentageformatter(x) {
    return x ? x.toString().replace(",", ".") : '';
}
export function removeSpaceCommaformatter(str) {
    var regex = /[.,\s]/g;
    str = str.toString()
    var result = str.replace(regex, '');
    return result
}

export function deleteData(tableName, ids) {
    const directus = new Directus(API_URL);
    return directus.items(tableName).deleteMany(ids);
}


export function getCounts(tableName, param) {
    const headers = {
        'Authorization': `Bearer ${directUSAPIToken()}`
    }
    return axios.get(`${API_URL}/items/${tableName}${param}`, {
        params: {},
        headers
    })
}

export const checkExist = async (tableName, filter) => {
    let options = {
        fields: ['id']
    }
    options.filter = filter;
    const result = await directUSAPICall().items(tableName).readMany(options);
    let count = 0
    if (result.data && result.data.length > 0) {
        count = result.data.length
    }
    return count
}

export const getMontlyPaymentRentorLease = (sumPurchaseValue, montlypaymentratio) => {
    let montlypaymentvalue = 0;
    if ((sumPurchaseValue && montlypaymentratio)) {
        montlypaymentvalue = (parseFloat(sumPurchaseValue) * parseFloat(montlypaymentratio)) / 100;
        // setCommisiontopartneramount(EOL_value_amt)
        return SVformatter(montlypaymentvalue)
        //   form.setFieldsValue({
        //     montly_payment_rent_or_lease: SVformatter(montlypaymentvalue)
        //   })
    }
}

export const getInterestProfit = (founding, clientInterest) => {
    let interestProfit = 0;
    if ((founding && clientInterest) && (clientInterest > founding)) {
        interestProfit = ((parseFloat(clientInterest) - parseFloat(founding))).toFixed(2);
    }
    return interestProfit
}

export const getAddPurchaseValue = (sumPurchaseValue, montlypaymentratio, noOfMonthLease, eolvalueAmt) => {
    let add_purchase_value = 0;
    if ((sumPurchaseValue && montlypaymentratio && noOfMonthLease)) {
        add_purchase_value = (((parseFloat(sumPurchaseValue)) * (parseFloat(montlypaymentratio) / 100)) * parseFloat(noOfMonthLease)) + parseInt(eolvalueAmt);
    }
    return SVformatter(add_purchase_value)
}

export const getCommisionAmount = (sumPurchaseValue, commisiontopartnerpercentage) => {
    let commission_amount = 0;
    if ((sumPurchaseValue && commisiontopartnerpercentage)) {
        commission_amount = (parseFloat(sumPurchaseValue) * parseFloat(commisiontopartnerpercentage)) / 100;
    }
    return SVformatter(commission_amount)
}

export const getCommissionAmount = (sumPurchaseValue, commisiontopartnerpercentage) => {
    let commission_amount = 0;
    if ((sumPurchaseValue && commisiontopartnerpercentage)) {
        commission_amount = (parseFloat(sumPurchaseValue) * parseFloat(commisiontopartnerpercentage)) / 100;
    }
    return SVformatter(commission_amount)
}

export const getEOLValueAmt = (sumPurchaseValue, eolpercentage) => {
    let EOLvalueamt = 0;
    if ((sumPurchaseValue && eolpercentage)) {
        EOLvalueamt = (parseFloat(sumPurchaseValue) * parseFloat(eolpercentage)) / 100;
        // setCommisiontopartneramount(EOL_value_amt)
    }
    return SVformatter(EOLvalueamt)
}

export const getNoticeBeforeNoticeDay = (latest_notice_day, notice_before_notice_month) => {
    let noticeperiodEOL = null
    if (latest_notice_day && notice_before_notice_month) {
        noticeperiodEOL = moment(latest_notice_day).subtract(notice_before_notice_month, 'M').subtract(1, "days").format('YYYY-MM-DD');
        noticeperiodEOL = moment(noticeperiodEOL, 'YYYY-MM-DD')
    }
    return noticeperiodEOL
}

export const getLatestNoticeDay = (eol_date, noticeperiodbeforeEOL) => {
    let noticeperiodEOL = null
    if (eol_date && noticeperiodbeforeEOL) {
        noticeperiodEOL = moment(eol_date).subtract(noticeperiodbeforeEOL, 'M').subtract(1, "days").format('YYYY-MM-DD')
    }
    return noticeperiodEOL

    // console.log("noticeperiodbeforeEOL", noticeperiodbeforeEOL)

}

export function getDatesFromDateRange(billing_period, rentperiod_tax, startDate, endDate, format, counter) {
    startDate = moment(startDate, format);
    endDate = moment(endDate, format);
    let dates = [];
    let fromDate = startDate.clone();
    let toDate = fromDate.clone().add(counter, "year").startOf("year").add(-1, "day");
    do {
        let from_date = fromDate.format(format);
        let to_date = (toDate < endDate) ? toDate.format(format) : endDate.format(format);
        let yearRents = getMonths(from_date, to_date);
        const perMonths = parseFloat(rentperiod_tax) / parseInt(billing_period)
        let rent_amount = (parseInt(yearRents) * perMonths);
        dates.push({
            // "agreement": agid,
            "from": from_date,
            "to": to_date,
            "months": yearRents,
            "rentamount": rent_amount ? Math.round(rent_amount) : 0
        });
        fromDate = moment(toDate, format).add(1, "day").clone();
        toDate = fromDate.clone().add(counter, "year").startOf("year").add(-1, "day");
    } while (fromDate < endDate);
    return dates;
}


export function getMonths(startdate, enddate) {
    let firstDate = moment(startdate, 'YYYY-MM-DD'); //Create date using string-format constructor
    let secondDate = moment(enddate, 'YYYY-MM-DD');
    let duration = moment.duration(secondDate.diff(firstDate));
    return Math.round(duration.asMonths());
}
export const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

export const generateExcel1 = (columns, currentRowData, fileName, page = null) => {
    let excelColumns = columns
        .map((column) => column.field);
    excelColumns = excelColumns.filter((item) => item !== undefined);

    currentRowData.forEach((response) => {
        response.date_created = moment(response.date_created).format('YYYY-MM-DD')
        response.date_updated = response.date_updated && moment(response.date_updated).format('YYYY-MM-DD')
        response.user_created = response?.user_created?.first_name + ' ' + response?.user_created?.last_name
        response.user_updated = response.user_updated && response?.user_updated?.first_name + ' ' + response?.user_updated?.last_name
    })
    if (page) {
        excelColumns = ['user_name', 'email', 'created_date', 'where'];
        currentRowData.forEach((response) => {
            response.user_name = response.user?.first_name
            response.email = response.user?.email
            response.created_date = response.timestamp
            response.where = response.ip
        })
    }
    let excelData = currentRowData.map((row) =>
        pickExcelFields(row, excelColumns)
    );
    // console.log("currentRowData", currentRowData)
    // console.log("excelColumns", excelColumns)
    //     //  return
    var worksheet = XLSX.utils.json_to_sheet(excelData, {
        header: excelColumns.filter(
            (col) => Object.keys(excelData[0]).indexOf(col) > -1
        ),
    });
    var new_workbook = XLSX.utils.book_new();
    // return
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Sheet1');

    XLSX.writeFile(new_workbook, fileName, { type: 'file' });
};

export const generateExcel = (gridApi, fileName, page = null, tablecolumns = null, loggedUserRole = null, selectedColumns = null, exportData = []) => {
    let excelColumns = gridApi.columnModel
        .columnDefs
        .map((column) => column.field);
    let excelColumnsHeader = gridApi.columnModel
        .columnDefs;

    if (tablecolumns && tablecolumns?.length > 0) {
        tablecolumns.forEach((item, index, object) => {
            if (loggedUserRole === 'client_admin' || loggedUserRole === 'client_user') {
                if (item.field === 'montly_payment_ratio' ||
                    item.field === "Interim_income" ||
                    item.field === "EOL_value_amt" ||
                    item.field === "EOL_percentage") {
                    object.splice(index, 1)
                }
            }
            if (loggedUserRole === 'partner_admin' || loggedUserRole === 'partner_user') {
                if (item.field === "Interim_no_of_days") {
                    object.splice(index, 1)
                }
            }
        })
        excelColumns = tablecolumns.map((column) => column.field);
        // if (selectedColumns && selectedColumns.length > 0) {
        //     excelColumns = selectedColumns
        // }
    }
    excelColumns = [...excelColumns]
    let currentRowData = [];
    if (exportData.length > 0) {
        currentRowData = exportData;
    } else {
        if (gridApi.getSelectedRows()?.length === 0) {
            currentRowData = gridApi.rowModel.rowsToDisplay?.map((row) => row.data);
        } else {
            currentRowData = gridApi.getSelectedRows().map((row) => row);
        }
    }
    // let worksheet1 = '';
    // let new_workbook = '';
    // let excelData = []
    // let excelHeaderName = excelColumnsHeader.map((column) => column.headerName);

    if (page === 'assets') {
        currentRowData.forEach((params) => {
            // if (params.notes?.length > 0) {
            //     notes = params.notes.map(({
            //         description
            //     }) => description).join(', ')
            // }
            params.agrement_info_no = params?.aggrement?.agrement_info_no
            params.typeofaggrements = params?.aggrement?.typeofaggrements;
            params.our_agreement_ref = params?.aggrement?.our_agreement_ref;
            params.partner_name = params.aggrement?.partner?.partner_name;
            params.client_name = params.aggrement?.client?.name;
            params.partner_org_no = params.aggrement?.partner?.partner_org_no;
            // params.partner_org_no = params.aggrement?.partner?.partner_org_no;
            params.clients_org_no = params.aggrement?.client?.clients_org_no;
            params.lease_start_date = moment(params?.aggrement?.lease_start_date).format('YYYY-MM-DD');
            params.eol_date = moment(params?.aggrement?.eol_date).format('YYYY-MM-DD');
            // params.ag_status = params?.aggrement?.ag_status;
            params.original_sign_date = params?.aggrement?.original_sign_date ? moment(params?.aggrement?.original_sign_date).format('YYYY-MM-DD') : '';
            params.type_of_leasing = params?.aggrement?.type_of_leasing;
            params.no_of_month_lease = params?.aggrement?.no_of_month_lease ? parseInt(params?.aggrement?.no_of_month_lease) : 0;
            params.montly_payment_ratio = params?.aggrement?.montly_payment_ratio ? SVPercentageformatter(params?.aggrement?.montly_payment_ratio) : ''
            params.billing_period = params?.aggrement?.billing_period ? parseInt(params?.aggrement?.billing_period) : 0;
            params.supplier_org_no = params?.aggrement?.supplier ? params?.aggrement?.supplier?.supplier_org_no : null;
            params.supplier_name = params?.aggrement?.supplier ? params?.aggrement?.supplier?.supplier_name : null;
            params.date_created = moment(params.date_created).format('YYYY-MM-DD')
            params.date_updated = params.date_updated && moment(params.date_updated).format('YYYY-MM-DD')
            params.user_created = params?.user_created ? params?.user_created?.first_name + ' ' + params?.user_created?.last_name : null
            params.user_updated = params?.user_updated ? params.user_updated && params?.user_updated?.first_name + ' ' + params?.user_updated?.last_name : null;
            params.dailyrent = params.dailyrent ? SVformatter(Math.round(params.dailyrent)) + ' kr' : '';
            params.price_total = params.price_total ? SVformatter(Math.round(params.price_total)) + ' kr' : '';
            params.priceper_unit = params.priceper_unit ? SVformatter(Math.round(params.priceper_unit)) + ' kr' : '';
            let rent_period = params?.rent_period ? params?.rent_period : 0
            params.rent_period = params.rent_period ? SVformatter(Math.round(params.rent_period)) + ' kr' : ''
            // params.dailyrent = params?.dailyrent ? Math.round(params?.dailyrent) : 0;
            let rent_month = Math.round(rent_period ? rent_period / parseInt(params?.aggrement?.billing_period) : 0)
            params.rent_month = rent_month ? SVformatter(rent_month)+' kr' : '';
            if (params?.aggrement && params?.aggrement?.ag_status && params?.aggrement?.lease_start_date) {
                // let monthDiff = getMonthDiff(params.data.eol_date);
                // let ag_status = getAgreementStatus(params.data, monthDiff).status || ''
                let statusList = ["EXTENDED"]
                let monthDiff = getMonthDiff(params?.aggrement?.eol_date);
                let ag_status = getAgreementStatus(params?.aggrement, monthDiff).status || ''
                if (!statusList.includes(params?.aggrement?.ag_status)) {
                    params.ag_status = ag_status;
                }
            }

        })
    }

    if (page === 'agreements') {
        currentRowData.forEach((params) => {
            // console.log("params", params)
            params.partner_name = params.partner?.partner_name;
            params.client_name = params?.client?.name;
            params.partner_org_no = params?.partner?.partner_org_no;
            params.clients_org_no = params?.client?.clients_org_no;
            params.lease_start_date = moment(params?.lease_start_date).format('YYYY-MM-DD');
            params.eol_date = moment(params?.eol_date).format('YYYY-MM-DD');
            if (params?.original_sign_date) {
                params.original_sign_date = moment(params?.original_sign_date).format('YYYY-MM-DD');
            }
            if (params?.latest_notice_day) {
                params.latest_notice_day = moment(params?.latest_notice_day).format('YYYY-MM-DD');
            }
            if (params?.notice_before_notice_day) {
                params.notice_before_notice_day = moment(params?.notice_before_notice_day).format('YYYY-MM-DD');
            }
            params.EOL_percentage = params?.EOL_percentage ? parseFloat(params?.EOL_percentage) : 0;
            params.billing_period = params?.billing_period ? parseInt(params?.billing_period) : 0;
            params.no_of_month_lease = params?.no_of_month_lease ? parseInt(params?.no_of_month_lease) : 0;
            params.supplier_org_no = params?.supplier?.supplier_org_no;
            params.supplier_name = params?.supplier?.supplier_name;
            params.date_created = moment(params.date_created).format('YYYY-MM-DD')
            params.date_updated = params.date_updated && moment(params.date_updated).format('YYYY-MM-DD')
            params.user_created = params?.user_created ? (params?.user_created?.first_name + ' ' + params?.user_created?.last_name) : ''
            params.user_updated = params.user_updated && params?.user_updated?.first_name + ' ' + params?.user_updated?.last_name
            params.montly_payment_ratio = params.montly_payment_ratio ? SVPercentageformatter(params.montly_payment_ratio)  : '';
            params.quartely_rent = params.quartely_rent ? SVformatter(Math.round(params.quartely_rent)) + ' kr' : ''
            params.Interim_income = params.Interim_income ? SVformatter(Math.round(params.Interim_income)) + ' kr' : ''
            params.sum_purchase_value = params.sum_purchase_value ? SVformatter(params.sum_purchase_value) + ' kr' : '';
            params.sum_purchase_value_1 = params.sum_purchase_value_1 ? SVformatter(params.sum_purchase_value_1) + ' kr' : '';
            params.sum_purchase_value_budget = params.sum_purchase_value_budget ? SVformatter(params.sum_purchase_value_budget) + ' kr' : '';
            params.montly_payment_rent_or_lease = params.montly_payment_rent_or_lease ? SVformatter(params.montly_payment_rent_or_lease) + ' kr' : '';
            params.commision_partner_amount = params.commision_partner_amount ? SVformatter(params.commision_partner_amount) + ' kr' : '';
            params.EOL_value_amt = params.EOL_value_amt ? SVformatter(params.EOL_value_amt) + ' kr' : '';
            // if (columnDef.type === 'currencyColumn') {
            //     params.data[columnDef.field] = params.data[columnDef.field] ? SVformatter(params.data[columnDef.field]) + ' kr' : '0 kr';
            // }
        })
    }
    // console.log("currentRowData", currentRowData)

    if (page === 'client') {
        currentRowData.forEach((item) => {
            item.partner_name = item.partner ? item.partner.partner_name : '';
            item.partner_org_no = item.partner ? item.partner.partner_org_no : '';
            item.client_name = item.client ? item.client.name : '';
            item.clients_org_no = item.client ? item.client.clients_org_no : '';
            item.phone_number = item.client ? item.client.phone_number : '';
            item.city = item.client ? item.client.city : '';
            item.zipcode = item.client ? item.client.zipcode : '';
            item.created_user = `${item.created_user ? item.created_user.firstname : ''} ${item.created_user ? item.created_user.lastname : ''}` || '';
            item.updated_user = `${item.updated_user ? item.updated_user.firstname : ''} ${item.updated_user ? item.updated_user.lastname : ''}` || '';
        })
    }
    const wb = new Excel.Workbook()
    const worksheet = wb.addWorksheet(fileName);
    let header = [];
    let headerName = [];
    excelColumnsHeader.map((val) => {
        let width = 16
        if (val.headerName && val.field !== 'actions') {
            header.push({ key: val.field, width: width });
            if (page === 'agreements' || page === 'assets') {
                headerName.push(val.headerName);
            } else {
                headerName.push(val.field);
            }
        }
    })
    worksheet.getRow(1).values = headerName;
    worksheet.columns = header // Asign header
    currentRowData.forEach(function (item, index) {
        worksheet.addRow(item)
    })
    wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName + '.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
    });

    // excelData = currentRowData.map((row) =>
    //     pickExcelFields(row, excelColumns)
    // );
    // worksheet1 = XLSX.utils.json_to_sheet(excelData, {
    //     header: excelColumns.filter(
    //         (col) => Object.keys(excelData[0]).indexOf(col) > -1
    //     ),
    // });
    // new_workbook = XLSX.utils.book_new();
    // // return
    // XLSX.utils.book_append_sheet(new_workbook, worksheet1, 'Sheet1');

    // XLSX.writeFile(new_workbook, fileName, { type: 'file' });
    return true
};


function KeyValues(obj, header) {
    return _.mapKeys(obj, (value, key) => {
        const name = header.find((itm) => itm.field === key)
        return name ? name.headerName : null
    });

}

export const pickExcelFields = (data, keys, header = null) => {

    return Object.keys(data)
        .filter((i) => keys.includes(i))
        .reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {});
};

export const getlistsAllAgreement = async (client = null, partner = null, userInfo) => {
    let fields = `id,agrement_info_no,heading,montly_payment_ratio,lease_start_date,billing_period`
    let options = {
        limit: -1,
        sort: '-id',
        fields: [fields]
    }
    if ((userInfo.role.name === "client_admin") || (userInfo.role.name === "client_user")) {
        if (partner) {
            options.filter = {
                _and:
                    [
                        {
                            "partner.id": {
                                _eq: partner
                            }
                        },
                        {
                            "client.id": {
                                _eq: userInfo.client.id
                            }
                        }
                    ]
            }
        }

    } else {
        if (partner) {
            options.filter = {
                "partner.id": {
                    _eq: partner
                }
            }
        }

        if (client) {
            options.filter = {
                "client.id": {
                    _eq: client
                }
            }
        }
    }
    // console.log("******** inside")
    const result = await directUSAPICall().items('aggrement').readMany(options);
    // console.log("******** result.data",result)

    if (result.data && result.data.length > 0) {

        return result.data
    } else {
        return []
    }

}
export const groupByAgreement = async (ids = null, action = null) => {
    let queryParam = "?limit=-1&groupBy[]=aggrement&aggregate[sum]=price_total,Interim_rent_cost&filter[aggrement][_nnull]=true"
    if (ids) {
        queryParam = `?ids=${ids}`
    }
    // &filter[aggrement.id][_eq]=
    getagreementassetsList(queryParam)
        .then(({ data: { data } }) => {
            return true
        })
        .catch(() => {
            // setLoading(false)
            //   setStatus('The login detail is incorrect')
        })
}
export const updateAgreementAsset = async (ids = null) => {
    let queryParam = ""
    if (ids) {
        queryParam = `?ids=${ids}`
    }
    // &filter[aggrement.id][_eq]=
    updateassetsList(queryParam)
        .then(({ data: { data } }) => {
        })
        .catch(() => {
            // setLoading(false)
            //   setStatus('The login detail is incorrect')
        })
}
export const createNotes = async (param) => {

    const result = await directUSAPICall().items('notes').readMany(param);
    // if (result.data && result.data.length > 0) {

    //     return result.data
    // } else {
    //     return []
    // }
}
export const getAgValuesFormat = async (partner, client, agreementList_Temp, totalAssets_List, selectedAgIds, userTypes, page, upcomingresponse = [], searchfield) => {
    let temp = agreementList_Temp || []
    let temp1 = agreementList_Temp || [];
    let productGroup = []
    let agreementDetails = {
        // noofagreements: null,
        // nextEndingDate: null,
        // monthyRent: null,
        // QuarterlyRent: null,
        // YearlyRent: null,
        // totalValue: null

    }
    let upComingBarChart = []
    let top3products = [];
    let tempAssets = totalAssets_List?.length > 0 ? totalAssets_List : []

    if (temp.length === 0) {
        return {
            upComingBarChart,
            productGroup,
            agreementDetails,
            agreementList: [],
            selectedAgIds: selectedAgIds,
            assetsList: tempAssets,
            top3products
        }
    }
    // let tempUpcomingresponse = upcomingresponse?.length > 0 ? upcomingresponse : []
    let agIds = [];
    // let storageValues = localStorage.getItem("filterValues") ? JSON.parse(localStorage.getItem("filterValues")) : null;
    // partner = storageValues?.partner ? storageValues?.partner : partner
    // console.log(client, "partner", partner)
    // console.log("selectedAgIds", selectedAgIds)
    if (userTypes.isClient) {
        if (client && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client });
        } else if (partner && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner?.id === partner });
        } else if (!partner && selectedAgIds?.length > 0) {
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (partner && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
            // setAgreementList(temp)
        } else {
            // setAgreementList(agreementList_Temp)
        }
    }
    if (userTypes.isPartner) {
        if (client && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client });
        } else if (!client && selectedAgIds?.length > 0) {
            // temp = temp?.length > 0 && temp.filter((row) => selected.indexOf(row.id) !== -1)
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (client && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client });
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
            // setAgreementList(temp)
        } else {
            // setAgreementList(agreementList_Temp)
        }
    }
    if (userTypes.isAdmin) {
        if (client && !partner && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client });
        } else if (client && !partner && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client });
        } else if (partner && !client && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner?.id === partner });
            // console.log("temp1", temp1)
        } else if (partner && !client && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner?.id === partner });
        } else if (!client && !partner && selectedAgIds?.length > 0) {
            // temp = temp?.length > 0 && temp.filter((row) => selected.indexOf(row.id) !== -1)
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (client && partner && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client && e.aggrement.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client && e.aggrement.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (client && partner && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client?.id === client && e.partner?.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client?.id === client && e.partner?.id === partner });
        }
    }
    if (temp1.length > 0) {
        agIds = temp1.map(
            (item) => item.id
        );
    }

    // tempUpcomingresponse = tempUpcomingresponse.filter(function (e) { return agIds.some(a => a === e.aggrement) });


    if (page === 'overview') {
        tempAssets.forEach((item) => {
            item.product_group = item.product_group ? item.product_group.toUpperCase() : 'OTHER'
        })
        tempAssets = tempAssets.filter(function (e) { return e.product_group != null });
        productGroup = _(tempAssets)
            .groupBy('product_group')
            .map(group => ({
                name: group[0]?.product_group,
                "value": parseFloat(group[0].sum.price_total) || 0
            }))
            .value();
        // totalAssetSUM = productGroup;
        productGroup = productGroup.sort((a, b) => b.value - a.value);
        productGroup = productGroup.slice(0, 10)
        //__________________Top 3 product groups_______________________________________

        top3products = [...productGroup].sort((a, b) => b.value - a.value).slice(0, 3);

        let currentQuarter = getQuarter();
        let tempAgData = [];
        let tempAgDataExten = [];
        let tempAgDataExpirequarter = [];
        let tempAgDataInterimquarter = [];

        temp1?.length > 0 && temp1.forEach((obj) => {

            if (obj.ag_status && obj.lease_start_date) {
                let monthDiff = getMonthDiff(obj.eol_date);
                let ag_status = getAgreementStatus(obj, monthDiff).status || ''
                let statusList = ["EXTENDED"]
                if (!statusList.includes(obj.ag_status)) {
                    obj.ag_status = ag_status
                }
            }
            if (['ENDING'].includes(obj.ag_status) && (currentQuarter === getQuarter(new Date(moment(obj.eol_date).format('YYYY-MM-DD'))))) {
                tempAgDataExpirequarter.push(obj)
            }
            if (['INTERIM'].includes(obj.ag_status)) {
                tempAgDataInterimquarter.push(obj)
            }
            if (FILTER_ASSET_STATUS.includes(obj.ag_status)) {
                tempAgData.push(obj)
            }
            if (['EXTENDED'].includes(obj.ag_status)) {
                tempAgDataExten.push(obj)
            }

        })

        // let total = _.round(_.sumBy(tempAgData, x => x.sum_purchase_value ? (parseFloat(x.sum_purchase_value)) : 0), 0)
        let total = 0
        let Extendedmonthly = 0;
        let interimincome = 0;
        //Interim report
        interimincome = _.round(_.sumBy(tempAgDataInterimquarter, x => x.Interim_income ? (parseFloat(x.Interim_income)) : 0), 0)
        //Extension rents per quarter
        Extendedmonthly = _.round(_.sumBy(tempAgDataExten, x => x.montly_payment_rent_or_lease ? (parseFloat(x.montly_payment_rent_or_lease)) : 0), 0)
        let monthly = _.round(_.sumBy(tempAssets, x => x.sum.dailyrent ? (parseFloat(x.sum.dailyrent) * 30) : 0), 0)
        const sortedData = _.sortBy(tempAgData, function (o) { return o.eol_date })
        let nextTimeData = []
        sortedData.forEach((element) => {
            if (new Date() <= new Date(element.eol_date)) {
                nextTimeData.push(element)
            }
        })
        total = _.round(_.sumBy(tempAssets, x => x.sum.price_total ? (parseFloat(x.sum.price_total)) : 0), 0);
        let tot_EOL_value_amt = _.round(_.sumBy(tempAgDataExten, x => x.EOL_value_amt ? (parseFloat(x.EOL_value_amt)) : 0), 0);
        let extendedEOLQuarterRent = Extendedmonthly ? SVformatter((Extendedmonthly * 3) - tot_EOL_value_amt) : 0
        let extendedEOLHalfRent = Extendedmonthly ? SVformatter((Extendedmonthly * 6) - tot_EOL_value_amt) : 0;
        let extendedEOLthreeforthRent = Extendedmonthly ? SVformatter((Extendedmonthly * 9) - tot_EOL_value_amt) : 0;
        agreementDetails = {
            noofagreements: tempAgData.length,
            nextEndingDate: nextTimeData?.length > 0 ? moment(nextTimeData[0].eol_date).format('YYYY-MM-DD') : '',
            monthyRent: SVformatter(monthly),
            QuarterlyRent: SVformatter(monthly * 3),
            YearlyRent: SVformatter(monthly * 12),
            totalValue: SVformatter(total),
            extendedQuarterRent: Extendedmonthly ? SVformatter(Extendedmonthly * 3) : 0,
            extendedEOLQuarterRent: extendedEOLQuarterRent,
            extendedEOLHalfRent: extendedEOLHalfRent,
            extendedEOLthreeforthRent: extendedEOLthreeforthRent,
            noofLeaseExpireQuarter: tempAgDataExpirequarter.length,
            interimincome: interimincome ? SVformatter(interimincome) : 0,
            eol_date: tempAgData.eol_date,
            tot_EOL_value_amt: tot_EOL_value_amt
        }
    }

    return {
        upComingBarChart,
        productGroup,
        agreementDetails,
        agreementList: temp,
        selectedAgIds: selectedAgIds,
        assetsList: tempAssets,
        top3products
    }

}

export function getQuarter(date = new Date()) {
    return Math.floor(date.getMonth() / 3 + 1);
}

export const getAgValuesFormat_new = async (partner, client, agreementList_Temp, totalAssets_List, selectedAgIds, userTypes, page, upcomingresponse = []) => {
    let temp = agreementList_Temp || []
    let temp1 = agreementList_Temp || []
    let tempAssets = totalAssets_List?.length > 0 ? totalAssets_List : []
    let tempUpcomingresponse = upcomingresponse?.length > 0 ? upcomingresponse : []
    let agIds = []
    if (userTypes.isClient) {

        if (partner && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner.id === partner });
        } else if (!partner && selectedAgIds?.length > 0) {
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (partner && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
            // setAgreementList(temp)
        } else {
            // setAgreementList(agreementList_Temp)
        }
    }
    if (userTypes.isPartner) {
        if (client && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client.id === client });

        } else if (!client && selectedAgIds?.length > 0) {
            // temp = temp?.length > 0 && temp.filter((row) => selected.indexOf(row.id) !== -1)
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (client && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client.id === client });
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
            // setAgreementList(temp)
        } else {
            // setAgreementList(agreementList_Temp)
        }
    }
    if (userTypes.isAdmin) {
        if (client && !partner && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client.id === client });
        } else if (client && !partner && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client.id === client });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client.id === client });
        } else if (partner && !client && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner.id === partner });
            // console.log("temp1", temp1)
        } else if (partner && !client && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.partner.id === partner });
        } else if (!client && !partner && selectedAgIds?.length > 0) {
            // temp = temp?.length > 0 && temp.filter((row) => selected.indexOf(row.id) !== -1)
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (client && partner && selectedAgIds?.length > 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client.id === client && e.aggrement.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client.id === client && e.aggrement.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter((row) => selectedAgIds.indexOf(row.id) !== -1)
        } else if (client && partner && selectedAgIds?.length === 0) {
            temp = temp?.length > 0 && temp.filter(function (e) { return e.client.id === client && e.partner.id === partner });
            temp1 = temp1?.length > 0 && temp1.filter(function (e) { return e.client.id === client && e.partner.id === partner });
        }
    }
    if (temp1.length > 0) {
        agIds = temp1.map(
            (item) => item.id
        );
    }
    tempAssets = tempAssets.filter(function (e) { return agIds.some(a => a === e.aggrement) });
    tempUpcomingresponse = tempUpcomingresponse.filter(function (e) { return agIds.some(a => a === e.aggrement) });
    // console.log("tempppppppppp", tempUpcomingresponse)
    let productGroup = []
    let agreementDetails = {}
    let UpComingBarChart = []
    if (page === 'overview') {
        /*cont asset product group*/
        const unique = Object.values(
            tempUpcomingresponse.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
        );
        var counts = _.countBy(unique, 'product_group');
        productGroup = _.map(counts, function (value, key) {
            return {
                name: key === 'null' ? "Other" : key,
                value: value
            };

        });

        /*-----------------------------*/
        let total = _.round(_.sumBy(temp1, x => x.sum_purchase_value ? (parseFloat(x.sum_purchase_value)) : 0), 0)
        let monthly = _.round(_.sumBy(temp1, x => x.montly_payment_rent_or_lease ? (parseFloat(x.montly_payment_rent_or_lease)) : 0), 0)
        const sortedData = _.sortBy(temp1, function (o) { return o.eol_date })
        let nextTimeData = []
        sortedData.forEach((element) => {
            if (new Date() <= new Date(element.eol_date)) {
                nextTimeData.push(element)
            }
        })
        agreementDetails = {
            noofagreements: temp1?.length > 0 && temp1.length,
            nextEndingDate: nextTimeData?.length > 0 ? moment(nextTimeData[0].eol_date).format('YYYY-MM-DD') : '',
            monthyRent: SVformatter(monthly),
            QuarterlyRent: SVformatter(monthly * 4),
            YearlyRent: SVformatter(monthly * 12),
            totalValue: SVformatter(total)

        }
        let rents_upcoming = [];
        UpComingBarChart = [];
        if (tempUpcomingresponse && tempUpcomingresponse.length > 0) {
            // tempUpcomingresponse.forEach((item) => {
            //     if (item.upcomingrents) {
            //         rents_upcoming.push(item.upcomingrents)
            //     }
            // })
            // console.log("rents_upcoming", rents_upcoming)
            // setUpcomingrents(rents_upcoming);
            let upcoming_rents = []
            // console.log(moment().format('YYYY'), "upcoming_rents", upcoming_rents);
            if (Array.isArray(upcoming_rents)) {
                upcoming_rents = tempUpcomingresponse.filter((item) => item.year >= moment().format('YYYY'));
                if (upcoming_rents) {
                    var groups = _.groupBy(upcoming_rents, function (value) {
                        return value.year;
                    });
                    UpComingBarChart = _.map(groups, function (group) {
                        return {
                            // agreement: group[0].agreement,
                            year: group[0].year,
                            "value": (_.round(_.sumBy(group, x => x.rentamount ? (parseFloat(x.rentamount)) : 0), 0)),
                        }
                    });
                }
            }
        }
    }

    return {
        UpComingBarChart,
        productGroup,
        agreementDetails,
        agreementList: temp,
        selectedAgIds: selectedAgIds,
        assetsList: tempAssets
    }

}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}
function isNumber(value) {
    return !isNaN(value);
}


export const FormatFilterValues = (filterColumns, page = null) => {

    let filters = [];
    let filterVal = {};
    let opList = { "_eq": "equals", "_contains": "contains", "_neq": "notEqual", "_ncontains": "notContains" }
    Object.keys(filterColumns).map((key, index) => {
        let values = filterColumns[key].filter
        let operator1 = filterColumns[key].type;
        let filterType = filterColumns[key].filterType;
        let operator = getKeyByValue(opList, operator1)
        if ((key) === "date_updated" || (key) === "date_created" || (key) === "lease_start_date" || (key) === "eol_date" || (key) === "original_sign_date") {
            values = values;
        } else {
            values = isNumber(values) ? values : values.trim()
        }

        if (values) {
            let obj = {};
            let obj1 = {};
            if ((key) === "client_name") {
                if (page) {
                    obj = {
                        "_or": [
                            {
                                "client": {
                                    "name": {
                                        [operator]: values
                                    }
                                }
                            },
                            {
                                "_or": [
                                    {
                                        "client": {
                                            "name": {
                                                [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                            }
                                        }
                                        // [(key)]: {
                                        //     [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                        // }
                                    },
                                    {
                                        "client": {
                                            "name": {
                                                [operator]: values.toLowerCase()
                                            }
                                        }
                                    },
                                    {
                                        "client": {
                                            "name": {
                                                [operator]: values.toUpperCase()
                                            }
                                        }
                                    }

                                ]

                            },


                        ]
                    }
                } else {
                    obj = {
                        "_or": [
                            {
                                "aggrement": {
                                    "client": {
                                        "name": {
                                            [operator]: values
                                        }
                                    }
                                }
                            },
                            {
                                "_or": [
                                    {
                                        "aggrement": {
                                            "client": {
                                                "name": {
                                                    [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                                }
                                            }
                                        }
                                        // [(key)]: {
                                        //     [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                        // }
                                    },
                                    {
                                        "aggrement": {
                                            "client": {
                                                "name": {
                                                    [operator]: values.toLowerCase()
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "aggrement": {
                                            "client": {
                                                "name": {
                                                    [operator]: values.toUpperCase()
                                                }
                                            }
                                        }
                                    }

                                ]

                            },


                        ]
                    }
                }

            } else if ((key) === "clients_org_no") {
                if (page) {
                    obj = {
                        "client": {
                            "clients_org_no": {
                                [operator]: values
                            }
                        }
                    }
                } else {
                    obj = {
                        "aggrement": {
                            "client": {
                                "clients_org_no": {
                                    [operator]: values
                                }
                            }
                        }
                    }
                }

            } else if ((key) === "no_of_month_lease") {
                obj = {
                    "aggrement": {
                        "no_of_month_lease": {
                            [operator]: values
                        }
                    }
                }
            }
            else if ((key) === "type_of_leasing") {
                obj = {
                    "_or": [
                        {
                            "aggrement": {
                                "type_of_leasing": {
                                    [operator]: values
                                }
                            }
                        },
                        {
                            "_or": [
                                {
                                    "aggrement": {
                                        "type_of_leasing": {
                                            [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                        }
                                    }
                                },
                                {
                                    "aggrement": {
                                        "type_of_leasing": {
                                            [operator]: values.toUpperCase()
                                        }
                                    }
                                },
                                {
                                    "aggrement": {
                                        "type_of_leasing": {
                                            [operator]: values.toLowerCase()
                                        }
                                    }
                                }

                            ]

                        },
                    ]
                }

            }
            else if ((key) === "invoicing_to") {
                obj = {
                    "_or": [
                        {
                            "aggrement": {
                                "invoicing_to": {
                                    [operator]: values
                                }
                            }
                        },
                        {
                            "_or": [
                                {
                                    "aggrement": {
                                        "invoicing_to": {
                                            [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                        }
                                    }
                                },
                                {
                                    "aggrement": {
                                        "invoicing_to": {
                                            [operator]: values.toUpperCase()
                                        }
                                    }
                                },
                                {
                                    "aggrement": {
                                        "invoicing_to": {
                                            [operator]: values.toLowerCase()
                                        }
                                    }
                                }

                            ]

                        },
                    ]
                }

            }

            else if ((key) === "ag_status") {
                obj = {
                    "_or": [
                        {
                            "aggrement": {
                                "ag_status": {
                                    [operator]: values
                                }
                            }
                        },
                        {
                            "_or": [
                                {
                                    "aggrement": {
                                        "ag_status": {
                                            [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                        }
                                    }
                                },
                                {
                                    "aggrement": {
                                        "ag_status": {
                                            [operator]: values.toLowerCase()
                                        }
                                    }
                                },
                                {
                                    "aggrement": {
                                        "ag_status": {
                                            [operator]: values.toUpperCase()
                                        }
                                    }
                                }

                            ]

                        },
                    ]
                }

            }

            else if ((key) === "supplier_name") {
                if (page) {
                    obj = {
                        "_or": [
                            {
                                "supplier": {
                                    "supplier_name": {
                                        [operator]: values
                                    }
                                }
                            },
                            {
                                "_or": [
                                    {
                                        "supplier": {
                                            "supplier_name": {
                                                [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                            }
                                        }
                                    },
                                    {
                                        "supplier": {
                                            "supplier_name": {
                                                [operator]: values.toUpperCase()
                                            }
                                        }
                                    },
                                    {
                                        "supplier": {
                                            "supplier_name": {
                                                [operator]: values.toLowerCase()
                                            }
                                        }
                                    }

                                ]

                            },
                        ]
                    }
                } else {
                    obj = {
                        "_or": [
                            {
                                "aggrement": {
                                    "supplier": {
                                        "supplier_name": {
                                            [operator]: values
                                        }
                                    }
                                }
                            },
                            {
                                "_or": [
                                    {
                                        "aggrement": {
                                            "supplier": {
                                                "supplier_name": {
                                                    [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "aggrement": {
                                            "supplier": {
                                                "supplier_name": {
                                                    [operator]: values.toUpperCase()
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "aggrement": {
                                            "supplier": {
                                                "supplier_name": {
                                                    [operator]: values.toLowerCase()
                                                }
                                            }
                                        }
                                    }

                                ]

                            },
                        ]
                    }
                }

            } else if ((key) === "supplier_org_no") {
                if (page) {
                    obj = {
                        "supplier": {
                            "supplier_org_no": {
                                [operator]: values
                            }
                        }
                    }
                } else {
                    obj = {
                        "aggrement": {
                            "supplier": {
                                "supplier_org_no": {
                                    [operator]: values
                                }
                            }
                        }
                    }
                }
            }
            else if ((key) === "partner_name") {
                if (page) {
                    obj = {
                        "_or": [
                            {
                                "partner": {
                                    "partner_name": {
                                        [operator]: values
                                    }
                                }
                            },
                            {
                                "_or": [
                                    {
                                        "partner": {
                                            "partner_name": {
                                                [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                            }
                                        }
                                    },
                                    {
                                        "partner": {
                                            "partner_name": {
                                                [operator]: values.toUpperCase()
                                            }
                                        }
                                    },
                                    {
                                        "partner": {
                                            "partner_name": {
                                                [operator]: values.toLowerCase()
                                            }
                                        }
                                    }

                                ]

                            },


                        ]
                    }
                } else {
                    obj = {
                        "_or": [
                            {
                                "aggrement": {
                                    "partner": {
                                        "partner_name": {
                                            [operator]: values
                                        }
                                    }
                                }
                            },
                            {
                                "_or": [
                                    {
                                        "aggrement": {
                                            "partner": {
                                                "partner_name": {
                                                    [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "aggrement": {
                                            "partner": {
                                                "partner_name": {
                                                    [operator]: values.toUpperCase()
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "aggrement": {
                                            "partner": {
                                                "partner_name": {
                                                    [operator]: values.toLowerCase()
                                                }
                                            }
                                        }
                                    }

                                ]

                            },


                        ]
                    }
                }


            } else if ((key) === "partner_org_no") {
                obj = {
                    "aggrement": {
                        "partner": {
                            "partner_org_no": {
                                [operator]: values
                            }
                        }
                    }
                }
            } else if ((key) === "agrement_info_no" || (key) === "lease_start_date" || (key) === "eol_date" || (key) === "original_sign_date") {
                // if ((key) === "client_name") {
                //     key = "name"
                // }
                obj = {
                    "aggrement": {
                        [key]: {
                            [operator]: values
                        }
                    }
                }
            } else {
                let comma = ''
                if (operator === '_eq') {
                    // if (item.fromdate) {
                    //     values = item.fromdate
                    // }
                    if (key === 'asset_id' || key === 'aggrement') {
                        if ((values.indexOf(' ') > -1) || (values.indexOf(',') > -1)) {
                            comma = values.split(' ') || values.split(',')
                        }
                    } else {
                        // if (values.indexOf(',') > -1) {
                        //     comma = values.split(',')
                        // }
                    }
                    if (comma?.length > 0) {
                        obj = {
                            [(key)]: {
                                ['_in']: comma.toString()
                            }
                        }
                    } else {
                        obj = {
                            [(key)]: {
                                [operator]: values
                            }
                        }
                    }

                } else if ((operator === '_in' || operator === '_nin')) {
                    if (values.indexOf(' ') > -1) {
                        comma = values.split(' ')
                    }
                    if (comma?.length > 0) {
                        obj = {
                            [(key)]: {
                                ['_in']: comma.toString()
                            }
                        }
                    } else {
                        obj = {
                            [(key)]: {
                                [operator]: values
                            }
                        }
                    }
                }
                else {
                    if (filterType === 'text') {
                        obj = {
                            "_or": [
                                {
                                    [(key)]: {
                                        [operator]: values
                                    }
                                },
                                {
                                    "_or": [
                                        {
                                            [(key)]: {
                                                [operator]: values.charAt(0).toUpperCase() + values.slice(1)
                                            }
                                        }, {
                                            [(key)]: {
                                                [operator]: values.toUpperCase()
                                            }
                                        }, {
                                            [(key)]: {
                                                [operator]: values.toLowerCase()
                                            }
                                        }

                                    ]

                                },


                            ]
                        }
                    } else {
                        obj = {
                            [(key)]: {
                                [operator]: values
                            }
                        }
                    }
                    // console.log("filterType",filterType)
                    // obj = {
                    //     [(key)]: {
                    //         [operator]: values
                    //     }
                    // }
                }
            }
            filters.push(obj)
        }
        if (filters?.length > 0) {
            filterVal = filters
        }
    })

    return filters
}
